import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { Container, Wrapper, Box } from "../../components/util";
import { InvoxySignupForm } from "../../components/forms";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

import FlexiTimeLogo from "../../images/logos/FlexiTime/FlexiTime_Full.svg";
import InvoxyLogo_Full from "../../images/logos/new-karmly/Karmly_Full.svg";
import InvoxyWilderness from "../../images/graphics/InvoxyStaticWilderness.svg";
import FromFlexiTime from "../../images/logos/FlexiTime/FromFlexiTime_White.svg";



// background-repeat: no-repeat;
// background-image: url(${InvoxyWilderness});
// background-size: cover;
// background-position: 50%;



const SignupContainer = styled(Container)`
  max-width: 100vw;
  min-height: 100vh;
  height: 100%;
  background-color: ${(props) => props.theme.colours.white};


  .product-by {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    color: ${(props) => props.theme.colours.dark};
    justify-content: center;

    img {
      height: 15px;
      margin-left: 7px;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      justify-content: center;
    }
  }
`;

const SignupBox = styled(Box)`
  max-width: 590px;
  background-color: ${(props) => props.theme.colours.white};
  border-radius: ${(props) => props.theme.styles.borderRadius};
  box-shadow: ${(props) => props.theme.styles.shadow};
  padding: 30px;
  margin-bottom: 50px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 15px;
  }
`;

const FromFlexiTimeBadge = styled.a`
  opacity: 1;
  z-index: 99;
  width: 130px;
  position: absolute;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
`;

const Signup = (props) => (
  <Layout fullPage>
    <Seo
      title="Karmly | 14 Day Free Trial"
      description="Create your Karmly account now and get 14 Days Free. No credit card required, easy set up with free support."
      pathname={props.location.pathname}
    />
    <SignupContainer>
      <Wrapper stackGap={80}>
        <Box stackGap={40}>
          <Box stackGap={8}>
            <Link to="/recruitment/">
              <img
                className="-center"
                src={InvoxyLogo_Full}
                alt="Karmly Logo"
                css={{ maxWidth: "250px" }}
              />
            </Link>
            <div className="product-by">
              A product by{" "}
              <a
                href="https://www.flexitime.works"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={FlexiTimeLogo}
                  alt="FlexiTime | Payroll & Employment Solutions"
                />
              </a>
            </div>
          </Box>

          <Box className="-textCenter" stackGap={5}>
            <h1>Start Free Trial</h1>
            <h4>14 Days Free · Easy Set Up · No Credit Card Required</h4>
          </Box>
          <SignupBox className="-center">
            <InvoxySignupForm />
            <p
              css={{ marginTop: "30px", opacity: "0.6" }}
              className="-textCenter"
            >
              © FlexiTime Limited. {new Date().getFullYear()}
            </p>
          </SignupBox>
        </Box>
      </Wrapper>
      <FromFlexiTimeBadge href="https://www.flexitime.works" target="_blank">
        <img className="-center" src={FromFlexiTime} />
      </FromFlexiTimeBadge>
    </SignupContainer>
  </Layout>
);

export default Signup;
